import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
export default [
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginRGcvZC480vMeta?.name ?? "login",
    path: loginRGcvZC480vMeta?.path ?? "/login",
    meta: loginRGcvZC480vMeta || {},
    alias: loginRGcvZC480vMeta?.alias || [],
    redirect: loginRGcvZC480vMeta?.redirect,
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  }
]